import { Colors, device, Stack, Text } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CreditTierSE } from '../../../api/credit/types';
import { formatInterestRate } from '../../../localization/percentages';
import { formatDate, parseDate } from '../../../utils/date';
import Modal from '../Modal';
import { ModalProps } from './types';

type Payload = {
  installments: CreditTierSE['pmt'];
  amount: number;
  totalInstallmentFees: number;
  totalCost: number;
  totalAmount: number;
  interest: number;
  effectiveInterest: number;
};
type Props = ModalProps<Payload>;

const TableWrapper = styled.div`
  overflow: auto;
  margin-inline: calc(-1 * var(--modal-horizontal-padding));
  padding-inline: var(--modal-horizontal-padding);

  @media (${device.tablet}) {
    margin-inline: unset;
    padding-inline: unset;
  }
`;

const ModalTable = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
`;

const Th = styled(Text)`
  text-align: right;
  padding-inline: 12px;
  border-bottom: 1px solid ${Colors.GRAY_200};
`;

const Td = styled(Text)<{ $isNumeric?: boolean }>`
  text-align: ${({ $isNumeric = true }) => ($isNumeric ? 'right' : 'left')};
  font-feature-settings: 'tnum';
  font-variant-numeric: tabular-nums;
  padding-inline: 12px;
  padding-block: 4px;
  border-bottom: 1px solid ${Colors.GRAY_200};

  tfoot & {
    border-bottom: none;
    padding-block: 16px;
  }
`;

const PaybackPlan = ({
  payload: { installments, amount, totalCost, totalAmount, interest, effectiveInterest, totalInstallmentFees }
}: Props) => {
  const { t } = useTranslation(['Modal', 'Common']);

  return (
    <Modal title={t('PaybackPlanModal.title')}>
      <Stack spacing={8}>
        <Text>{t('PaybackPlanModal.messageBelowTitle1')}</Text>
        <Text>{t('PaybackPlanModal.messageBelowTitle2')}</Text>
        <TableWrapper>
          <ModalTable>
            <thead>
              <tr>
                <Th as="th">{t('PaybackPlanModal.dueDate')}</Th>
                <Th as="th">{t('PaybackPlanModal.interest')}</Th>
                <Th as="th">{t('PaybackPlanModal.amortization')}</Th>
                <Th as="th">{t('PaybackPlanModal.installmentFee')}</Th>
                <Th as="th">{t('PaybackPlanModal.totalToPay')}</Th>
              </tr>
            </thead>
            <tbody>
              {installments.map((item, index) => (
                <tr key={index}>
                  <Td as="td">{formatDate(parseDate(item.dueDate))}</Td>
                  <Td as="td">{t('Common:moneyWithTrailingZeros', { amount: item.interest })}</Td>
                  <Td as="td">{t('Common:moneyWithTrailingZeros', { amount: item.principal })}</Td>
                  <Td as="td">{t('Common:moneyWithTrailingZeros', { amount: item.installmentFee })}</Td>
                  <Td as="td">{t('Common:moneyWithTrailingZeros', { amount: item.total })}</Td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <Td as="td" weight="bold" $isNumeric={false}>
                  {t('PaybackPlanModal.total')}
                </Td>
                <Td as="td" weight="bold">
                  {t('Common:moneyWithTrailingZeros', { amount: totalCost })}
                </Td>
                <Td as="td" weight="bold">
                  {t('Common:moneyWithTrailingZeros', { amount })}
                </Td>
                <Td as="td" weight="bold">
                  {t('Common:moneyWithTrailingZeros', { amount: totalInstallmentFees })}
                </Td>
                <Td as="td" weight="bold">
                  {t('Common:moneyWithTrailingZeros', { amount: totalAmount })}
                </Td>
              </tr>
            </tfoot>
          </ModalTable>
        </TableWrapper>

        <Text>
          {t('PaybackPlanModal.messageBelowTable', {
            effectiveInterest: formatInterestRate(effectiveInterest),
            interest: formatInterestRate(interest),
            totalAmount,
            amount,
            totalCost
          })}
        </Text>
      </Stack>
    </Modal>
  );
};

export default PaybackPlan;
