import { endpoints } from '../endpoints';
import { validateResponseData } from '../utils';
import axiosInstance from '../utils/axiosInstance';
import { getUsersMeResponseSchema } from './schemas';

export const getUsersMe = async () => {
  const response = await axiosInstance.get(endpoints.users.me);
  return validateResponseData(getUsersMeResponseSchema, response.data);
};

export const acknowledgeTermsAndConditions = async () => {
  const response = await axiosInstance.patch(endpoints.users.me, {
    isLatestTermsAndConditionsAcknowledged: true
  });
  return validateResponseData(getUsersMeResponseSchema, response.data);
};
