import { z, ZodTypeAny } from 'zod';
import { Country } from '../../localization/constants';
import { environment } from '../../utils/env.utils';

const getSEUsersMeResponseSchema = z.object({
  email: z.string(),
  mobile: z.string(),
  isLatestTermsAndConditionsAcknowledged: z.boolean()
});

export const getUKUsersMeResponseSchema = getSEUsersMeResponseSchema.and(
  z.object({
    address: z.object({
      buildingNumber: z.string().optional(),
      buildingName: z.string().optional(),
      street: z.string(),
      postCode: z.string(),
      town: z.string()
    })
  })
);

export const getUsersMeResponseSchemaMap = {
  SE: getSEUsersMeResponseSchema,
  UK: getUKUsersMeResponseSchema
} satisfies Record<Country, ZodTypeAny>;

export const getUsersMeResponseSchema = getUsersMeResponseSchemaMap[environment.country];
