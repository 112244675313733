import { LocalStorageKeys } from '../constants/LocalStorage';
import { environment } from '../utils/env.utils';

const loadDevTools = (callback: () => void) => {
  const isDisabled = localStorage.getItem(LocalStorageKeys.DEVTOOLS) === 'false';
  const isDevOrTest = ['develop', 'local', 'test'].includes(environment.env);

  if (isDevOrTest && !isDisabled) {
    void import('./devtools').then(devTools => devTools.install()).finally(callback);
  } else {
    callback();
  }
};

export default loadDevTools;
