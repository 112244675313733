/**
 * Helper function for runtime assertions in TypeScript.
 * Throws an error if the condition is false.
 *
 * @param condition - The boolean condition to check
 * @param message - Optional message to include in the error if assertion fails
 * @throws Error - Throws an error with the provided message if condition is false
 *
 * @example
 * assert(value !== undefined, 'Value must be defined');
 */
export function assert(condition: boolean, message = 'No information provided'): asserts condition {
  if (!condition) {
    throw new Error('Assertion failed: ' + message);
  }
}

/**
 * Helper function for exhaustiveness checking in TypeScript.
 * Used to ensure all cases in a switch/if-else statement are handled.
 * The function will never actually be executed - it's for type checking only.
 *
 * @param value - A value of type 'never', indicating all cases should have been handled
 * @throws Error - Always throws an error if called, since it should be unreachable
 *
 * @example
 * switch (someEnum) {
 *   case 'A': return handleA();
 *   case 'B': return handleB();
 *   default: assertUnreachable(someEnum); // Type error if enum has other cases
 * }
 */
export const assertUnreachable = (value: never) => {
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  throw new Error(`Unreachable code: ${value}`);
};
