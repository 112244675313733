import { nonBreakingSpace } from '../constants/specialCharacters';
import { assert, assertUnreachable } from '../utils/typescript';
import { Currency, getCurrentCurrency } from './constants';
import { removeTrailingZeros } from './utils';

export type FormatOptions = {
  displayTrailingZeros?: boolean;
  displaySign?: boolean;
};

type FormatFunction = (value: number, options?: FormatOptions) => string;

const initialFormatOptions: FormatOptions = {
  displaySign: true,
  displayTrailingZeros: false
};

const formatSEK: FormatFunction = (amount, options = {}) => {
  const { displaySign, displayTrailingZeros } = { ...initialFormatOptions, ...options };

  let amountStr = amount.toFixed(2);
  if (Math.abs(amount) >= 10000) {
    amountStr = amountStr.replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, ' '); // Starting after the dot, find groups of 3 digits and replace them with 3 digits plus space
  }
  if (!displayTrailingZeros) {
    amountStr = removeTrailingZeros(amountStr);
  }
  if (displaySign) {
    amountStr = `${amountStr} kr`;
  }

  return amountStr.replace('.', ',');
};

const formatGBP: FormatFunction = (amount, options = {}) => {
  const { displaySign, displayTrailingZeros } = { ...initialFormatOptions, ...options };
  const formatter = new Intl.NumberFormat('en-GB', {
    ...(displaySign
      ? {
          style: 'currency',
          currency: 'GBP'
        }
      : {}),
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  let amountStr = formatter.format(amount);
  if (!displayTrailingZeros) {
    amountStr = removeTrailingZeros(amountStr);
  }

  return amountStr;
};

type FormatCurrencyOptions = FormatOptions & {
  currency: Currency;
};

export const formatCurrency = (amount: number, options: Partial<FormatCurrencyOptions> = {}): string => {
  const { currency, ...formatOptions } = {
    ...initialFormatOptions,
    currency: getCurrentCurrency(),
    ...options
  };

  let formattedAmount: string | undefined;

  switch (currency) {
    case 'SEK': {
      formattedAmount = formatSEK(amount, formatOptions);
      break;
    }
    case 'GBP': {
      formattedAmount = formatGBP(amount, formatOptions);
      break;
    }
    default: {
      assertUnreachable(currency);
    }
  }

  assert(formattedAmount !== undefined);

  return formattedAmount.replace(/ /g, nonBreakingSpace);
};
