import { z } from 'zod';

export const creditTierSESchema = z.object({
  id: z.string(),
  amount: z.number(),
  effectiveInterest: z.number(),
  interest: z.number(),
  monthlyPayment: z.number(),
  period: z.number(),
  totalInstallmentFees: z.number(),
  totalAmount: z.number(),
  totalLoanCost: z.number(),
  fees: z.object({
    setupFee: z.number(),
    withdrawalFee: z.number(),
    installmentFee: z.number(),
    directDebitMissingFee: z.number()
  }),
  pmt: z
    .object({
      dueDate: z.string(),
      interest: z.number(),
      principal: z.number(),
      returnPeriod: z.number(),
      installmentFee: z.number(),
      total: z.number()
    })
    .array()
});

export const creditTiersSEResponseSchema = z.object({
  data: creditTierSESchema.array()
});

export const featuresStepSchema = z.object({
  stepAmount: z.number(),
  principalLeft: z.number(),
  interestLeft: z.number(),
  monthlyAmount: z.number(),
  totalRepayableAmount: z.number(),
  period: z.number()
});

export const withdrawFeaturesStepSchema = featuresStepSchema.extend({
  //TODO: Check if this validation can become country specific (FCSE-5955)
  fees: z.object({
    withdrawalFee: z.number().optional(),
    setupFee: z.number().optional()
  })
});

export const creditFeaturesResponseSchema = z.object({
  deposit: featuresStepSchema.array(),
  monthlyPayment: featuresStepSchema.array(),
  withdraw: withdrawFeaturesStepSchema.array()
});

export const initiateTierMigrationResponseSchema = z.object({
  id: z.string(),
  autoStartToken: z.string(),
  orderId: z.string()
});
