import { Colors, device } from '@fairlo/ui-kit';
import { ToastContainer as ToastifyContainer } from 'react-toastify';
import styled from 'styled-components';

const ToastContainer = styled(ToastifyContainer)`
  --toastify-color-light: ${Colors.RED_100};
  --toastify-container-width: 100%;
  --toastify-toast-min-height: 0;

  &&&.Toastify__toast-container {
    position: fixed;
    top: 88px;
    left: 50%;
    transform: translateX(-50%);
    padding: 0;

    @media (${device.tablet}) {
      top: 24px;
    }
  }

  .Toastify__toast {
    padding: 20px;
    border-radius: 8px;
    box-shadow: none;
    width: calc(100% - 48px);
    max-width: 512px;
    align-items: flex-start;
    gap: 8px;
  }

  .Toastify__toast-icon {
    margin: 0;
  }
`;

export default ToastContainer;
