import { QueryParam } from '../constants';
import { routeMap } from '../routes/routeMap';

/**
 * Constructs a URL by combining a route with optional parameters
 *
 * @param route - The route path to construct the URL from
 * @param options - Optional configuration object
 * @param options.queryParams - Key-value pairs to add as query parameters
 * @param options.base - Base URL to use (defaults to window.location.origin)
 * @param options.relative - Whether to return a relative URL path instead of absolute
 * @param options.hash - Hash fragment to append to the URL
 *
 * @returns The constructed URL string
 */
export const constructURL = (
  route: string,
  {
    relative = false,
    base = window.location.origin,
    queryParams = {},
    hash
  }: {
    relative?: boolean;
    base?: string;
    queryParams?: Record<string, string>;
    hash?: string;
  } = {}
) => {
  const url = new URL(route, base);

  for (const [key, value] of Object.entries(queryParams)) {
    url.searchParams.set(key, value);
  }

  if (hash) {
    url.hash = hash;
  }

  return relative ? url.href.slice(url.origin.length) : url.href;
};

export const constructSelectAccountURL = (url: string) =>
  `${routeMap.SE.selectAccount.root}?${new URLSearchParams({
    [QueryParam.SELECT_ACCOUNT_REDIRECT]: url
  }).toString()}`;

export const getBankIdAppUrl = (autoStartToken: string, redirect = 'null') =>
  constructURL('bankid:///', {
    queryParams: {
      autostarttoken: autoStartToken,
      redirect
    }
  });
export const getRelativePathName = (path: string, depth = 1) => {
  const pathSegments = path.split('/').filter(Boolean);

  if (pathSegments.length < depth) {
    throw new Error(`Path ${path} does not have enough segments to get the relative path name`);
  }

  return `/${pathSegments.slice(-depth).join('/')}`;
};

export const getQueryParams = (search = window.location.search) => {
  const searchParams = new URLSearchParams(search);
  return Object.fromEntries(searchParams.entries());
};
