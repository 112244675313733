import { MeResponse } from '../../api';
import { ProductName } from '../../constants';

export const selectUsername = (me: MeResponse) => me.givenName ?? me.firstName;

export const selectFullName = (me: MeResponse) => `${me.firstName ?? me.givenName} ${me.lastName}`;

export const selectIsFirstDebtTransfer = (me: MeResponse) => !me.activatedProducts.debtConsolidation;

export const selectRemainingDebt = (me: MeResponse) => {
  const creditDebt = me.activatedProducts.credit.amountUsed;
  const debtConsolidationDebt = me.activatedProducts.debtConsolidation?.amountUsed ?? 0;
  return creditDebt + debtConsolidationDebt;
};

export const selectActivatedProduct = (productName: ProductName) => (me: MeResponse) =>
  me.activatedProducts[productName];

export const createAnnualInterestSelector = (productName: ProductName) => (me: MeResponse) =>
  me.activatedProducts[productName]?.annualInterest ?? 0;
