import { AnchorButton, Button, Text } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';

import marketingSiteRouteMap from '../../../constants/marketingSiteRouteMap';
import { useAcknowledgeTermsAndConditionsMutation } from '../../../pages/Products/hooks/useShowTermsAndConditionsUpdate';
import Modal from '../Modal';
import { ModalProps } from './types';

const TermsAndConditionsUpdated = ({ closeModal }: ModalProps) => {
  const { t } = useTranslation(['Modal', 'ErrorToast']);

  const { mutate: acknowledgeTermsAndConditions } = useAcknowledgeTermsAndConditionsMutation();

  return (
    <Modal title={t('TermsAndConditionsUpdated.title')}>
      <Text>{t('TermsAndConditionsUpdated.info')}</Text>
      <Modal.Actions>
        <AnchorButton
          onClick={() => {
            closeModal();
            acknowledgeTermsAndConditions();
          }}
          href={marketingSiteRouteMap.termsAndConditions}
          target="_blank"
          variant="outlined"
        >
          {t('TermsAndConditionsUpdated.button1')}
        </AnchorButton>
        <Button
          onClick={() => {
            closeModal();
            acknowledgeTermsAndConditions();
          }}
        >
          {t('TermsAndConditionsUpdated.button2')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default TermsAndConditionsUpdated;
