import { z } from 'zod';
import { moneySchema } from '../schemas';

export const getWithdrawalResponseSchema = z.object({
  withdrawnAmount: moneySchema,
  customerCosts: z.object({
    total: moneySchema,
    interest: moneySchema,
    principal: moneySchema,
    outstandingPrincipalBalance: moneySchema,
    totalPayments: z.number(),
    //TODO: Check if this validation can become country specific (FCSE-5955)
    fees: z.object({
      withdrawalFee: z.number().optional(),
      setupFee: z.number().optional(),
      totalInstallmentFees: z.number().optional()
    })
  }),
  companyCosts: z.object({
    total: moneySchema,
    funding: moneySchema,
    lossProvision: moneySchema,
    servicing: moneySchema,
    labour: moneySchema
  }),
  companyProfit: moneySchema
});
