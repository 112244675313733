import { OverdueInfoResponse } from '../../types/credit';
import { environment } from '../../utils/env.utils';
import { endpoints } from '../endpoints';
import { validateResponseData } from '../utils';
import axiosInstance, { createAxiosInstance } from '../utils/axiosInstance';
import {
  creditFeaturesResponseSchema,
  creditTiersSEResponseSchema,
  initiateTierMigrationResponseSchema
} from './schemas';
import { CreditActionsResponse, CreditTierMigrationInfoResponse } from './types';

export const getCreditActions = async () => {
  const response = await axiosInstance.get<CreditActionsResponse>(endpoints.credit.actions);
  return response.data;
};

export const getOverdueInfo = async () => {
  const response = await axiosInstance.get<OverdueInfoResponse>(endpoints.credit.overdue);
  return response.data;
};

export const getFeatures = async () => {
  const response = await axiosInstance.get(endpoints.credit.featuresSteps);
  return validateResponseData(creditFeaturesResponseSchema, response.data);
};

export const getCreditTiers = async () => {
  const tempInstance = createAxiosInstance(environment.apiRoot);
  const response = await tempInstance.get(endpoints.credit.amount);
  return validateResponseData(creditTiersSEResponseSchema, response.data).data;
};

export const terminateCredit = ({ reason }: { reason: string }) =>
  axiosInstance.post<void>(endpoints.credit.terminate, { reason });

export const getCreditTierMigrationInfo = async () => {
  const response = await axiosInstance.get<CreditTierMigrationInfoResponse>(
    endpoints.credit.tierMigration.root
  );
  return response.data;
};

export const initiateTierMigration = async () => {
  const response = await axiosInstance.post(endpoints.credit.tierMigration.root);
  return validateResponseData(initiateTierMigrationResponseSchema, response.data);
};

export const migrateTier = async (tierMigrationId: string) => {
  await axiosInstance.post<void>(endpoints.credit.tierMigration.tierMigrationId(tierMigrationId));
};
