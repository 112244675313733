import { Colors, Icon, Text } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';
import { ToastOptions, Zoom, toast } from 'react-toastify';
import styled from 'styled-components';

const IconWrapper = styled.div`
  height: var(--line-height-text-m);
  width: var(--line-height-text-m);
  display: flex;
  justify-content: center;
  align-items: center;
`;

type ToastTextProps = {
  children: string;
};

const ToastText = ({ children }: ToastTextProps) => {
  const { t, i18n } = useTranslation();
  const isTranslationKey = i18n.exists(children);

  return <Text size="m">{isTranslationKey ? t(children) : children}</Text>;
};

export const showErrorToast = (message: string, options: ToastOptions = {}) => {
  toast.error(<ToastText>{message}</ToastText>, {
    toastId: 'error',
    position: 'top-center',
    transition: Zoom,
    hideProgressBar: true,
    closeButton: false,
    closeOnClick: true,
    icon: () => (
      <IconWrapper>
        <Icon size={24} glyph="info" color={Colors.RED_800} />
      </IconWrapper>
    ),
    ...options
  });
};

export { default as ToastContainer } from './ToastContainer';
