import { endpoints } from '../../endpoints';
import { validateResponseData } from '../../utils';
import axiosInstance from '../../utils/axiosInstance';
import { directPaymentResponseSchema } from './schemas';
import {
  DirectPaymentStatusResponse,
  InitiateDirectPaymentPayload,
  InitiateDirectPaymentResponse,
  SelectAccountPayload,
  SelectAccountResponse,
  UpdateAccountPayload,
  UpdateAccountResponse
} from './types';

export const selectAccount = async (payload: SelectAccountPayload) => {
  const response = await axiosInstance.post<SelectAccountResponse>(
    endpoints.paymentProvider.account,
    payload
  );
  return response.data.response;
};

export const updateAccount = async (payload: UpdateAccountPayload) => {
  const response = await axiosInstance.patch<UpdateAccountResponse>(
    endpoints.paymentProvider.account,
    payload
  );
  return response.data.response;
};

export const getDirectPayment = async (paymentId: string) => {
  const response = await axiosInstance.get(endpoints.paymentProvider.directPayment.paymentId(paymentId).root);
  return validateResponseData(directPaymentResponseSchema, response.data);
};

export const initiateDirectPayment = async (paymentId: string, payload: InitiateDirectPaymentPayload) => {
  const response = await axiosInstance.post<InitiateDirectPaymentResponse>(
    endpoints.paymentProvider.directPayment.paymentId(paymentId).paymentRequest,
    payload
  );
  return response.data.paymentProviderToken;
};

export const getDirectPaymentStatus = async (paymentId: string) => {
  const response = await axiosInstance.get<DirectPaymentStatusResponse>(
    endpoints.paymentProvider.directPayment.paymentId(paymentId).paymentRequest
  );
  return response.data;
};

export const abortDirectPayment = async (paymentId: string) => {
  const response = await axiosInstance.delete<''>(
    endpoints.paymentProvider.directPayment.paymentId(paymentId).root
  );

  return response.data;
};
