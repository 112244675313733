import { Button, Text } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';

import useMeQuery from '../../../hooks/useMeQuery';
import Modal from '../Modal';
import { ModalProps } from './types';

const WithdrawalNotPossible = ({ confirmAction }: ModalProps) => {
  const { data: me } = useMeQuery();
  const { t } = useTranslation('Modal');

  if (!me) {
    return null;
  }

  return (
    <Modal title={t('WithdrawalNotPossible.title')}>
      <Text>
        {t('WithdrawalNotPossible.info', {
          minimumWithdrawalAmount: me.activatedProducts.credit.minimumWithdrawalAmount
        })}
      </Text>
      <Modal.Actions>
        <Button onClick={confirmAction}>{t('WithdrawalNotPossible.button')}</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default WithdrawalNotPossible;
