type DateFormat = 'YYYY-MM-DD' | 'YYYY-DD-MM HH:mm' | 'DD-MM-YY' | 'DD-MM' | 'MM-YYYY';
/**
 * Formats date provided in the specified format
 * Default format: YYYY-MM-DD
 * @param date
 * @param dateFormat
 */
export const formatDate = (date: Date, dateFormat: DateFormat = 'YYYY-MM-DD') => {
  const DD = String(date.getDate()).padStart(2, '0');
  const MM = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
  const YYYY = date.getFullYear();
  switch (dateFormat) {
    case 'YYYY-MM-DD': {
      return `${YYYY}-${MM}-${DD}`;
    }
    case 'YYYY-DD-MM HH:mm': {
      const HH = date.getHours();
      const mm = String(date.getMinutes()).padStart(2, '0');
      return `${YYYY}-${DD}-${MM} ${HH}:${mm}`;
    }
    case 'DD-MM-YY': {
      return `${DD}-${MM}-${`${YYYY}`.slice(-2)}`;
    }
    case 'DD-MM': {
      return `${DD}-${MM}`;
    }
    case 'MM-YYYY': {
      return `${MM}-${YYYY}`;
    }
  }
  return `${YYYY}-${MM}-${DD}`;
};

/**
 * Parse a date string in YYYYMMDD format
 * @param dateStr
 */
export const parseDate = (dateStr: string) => {
  const year = dateStr.substring(0, 4);
  const month = dateStr.substring(4, 6);
  const day = dateStr.substring(6, 8);
  return new Date(`${year}-${month}-${day}`);
};

export const addDays = (date: Date, daysToAdd: number) => {
  const result = new Date(date);
  result.setDate(result.getDate() + daysToAdd);
  return result;
};

/**
 * Returns if the year, month, day consist a valid date
 */
export const isValidDate = (year: number, month: number, day: number) => {
  const zeroBasedMonth = month - 1;
  const d = new Date(year, zeroBasedMonth, day);

  return d.getFullYear() === year && d.getMonth() === zeroBasedMonth && d.getDate() === day;
};

export const getYearMonthDay = (date: Date) => ({
  day: date.getDate(),
  month: date.getMonth() + 1,
  year: date.getFullYear()
});

export function isFutureDate(date: Date) {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const dateObj = new Date(date);
  dateObj.setHours(0, 0, 0, 0);

  return today < dateObj;
}
