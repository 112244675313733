import { useMutation } from '@tanstack/react-query';
import API from '../../../../api';
import { queryKeys } from '../../../../api/utils/queryKeys';
import { queryClient } from '../../../../libs/tanstackQuery';

export const useAcknowledgeTermsAndConditionsMutation = () =>
  useMutation({
    mutationFn: API.users.acknowledgeTermsAndConditions,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: queryKeys.usersMe });
    }
  });
