export const endpoints = {
  credit: {
    actions: '/credit/actions',
    amount: '/credit/amount',
    featuresSteps: '/credit/features-steps',
    overdue: '/credit/overdue',
    terminate: '/credit/terminate',
    tierMigration: {
      root: '/credit/tier-migration',
      tierMigrationId: (tierMigrationId = ':tierMigrationId') =>
        `/credit/tier-migration/${tierMigrationId}` as const
    }
  },
  paymentProvider: {
    account: '/payment-provider/account',
    directPayment: {
      paymentId: (paymentId = ':paymentId') =>
        ({
          root: `/payment-provider/direct-payment/${paymentId}`,
          paymentRequest: `/payment-provider/direct-payment/${paymentId}/payment-request`,
          generateQr: `/payment-provider/direct-payment/${paymentId}/generate-qr`
        }) as const
    }
  },
  users: {
    me: '/users/me'
  }
} as const;
