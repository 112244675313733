import * as Sentry from '@sentry/react';
import LogRocket from 'logrocket';
import useRetryUntilResolved from '../hooks/useRetryUntilResolved';
import { environment } from '../utils/env.utils';
import { maskSensitiveData, redactedValue } from '../utils/sensitiveData';

type NetworkConfig = NonNullable<NonNullable<Parameters<typeof LogRocket.init>[1]>['network']>;
type Request = Parameters<NonNullable<NetworkConfig['requestSanitizer']>>[0];
type Response = Parameters<NonNullable<NetworkConfig['responseSanitizer']>>[0];

const sanitizeHeaders = (headers: Record<string, string | null | undefined>) => {
  const maskedHeaders = { ...headers };
  for (const key in headers) {
    if (/authorization/i.test(key)) {
      maskedHeaders[key] = redactedValue;
    }
  }

  return maskedHeaders;
};

const sanitizeBody = (
  body: string | undefined,
  headers: Record<string, string | null | undefined>
): string | undefined => {
  const contentType = headers['Content-Type'] ?? '';
  const isUrlEncodedRequest = contentType.includes('form-urlencoded');

  if (!body || isUrlEncodedRequest) {
    return undefined;
  }

  let parsedBody: Record<string, unknown>;
  try {
    parsedBody = JSON.parse(body) as Record<string, unknown>;
  } catch {
    return undefined;
  }

  const maskedBody = maskSensitiveData(parsedBody);
  return JSON.stringify(maskedBody);
};

const sanitizeRequestOrResponse = <T extends Request | Response>(requestOrResponse: T): T | null => {
  const { body, headers } = requestOrResponse;

  const sanitizedHeaders = sanitizeHeaders(headers);
  const sanitizedBody = sanitizeBody(body, headers);

  requestOrResponse.headers = sanitizedHeaders;
  if (sanitizedBody !== undefined) {
    requestOrResponse.body = sanitizedBody;
  }

  return requestOrResponse;
};

const apiRootRegExp = new RegExp(environment.apiRoot, 'i');
const sanitizeRequest = (request: Request): Request | null => {
  if (!apiRootRegExp.test(request.url)) {
    return null;
  }

  return sanitizeRequestOrResponse(request);
};

export const initializeLogRocket = () => {
  if (environment.env !== 'production') {
    return;
  }

  LogRocket.init(environment.logRocketAppId, {
    rootHostname: environment.rootHostName,
    release: environment.version,
    shouldCaptureIP: false,
    network: {
      requestSanitizer: sanitizeRequest,
      responseSanitizer: sanitizeRequestOrResponse
    }
  });
};

export const useLogRocketSessionInSentry = () => {
  useRetryUntilResolved(() => {
    // LogRocket is only available in production, skip this hook in development
    if (environment.env !== 'production') {
      return true;
    }

    if (!Sentry.withScope || !LogRocket) {
      return false;
    }

    LogRocket.getSessionURL(sessionURL => {
      Sentry.withScope(scope => {
        scope.setExtra('sessionURL', sessionURL);
      });
    });

    return true;
  });
};
